<template>
  <div class="w-full vs-con-loading__container" id="loadingTreinamento">
    <vs-row vs-w="12" vs-type="flex" vs-align="flex-start" vs-justify="center" :key="reRender">
      <vs-col
        vs-w="12"
        class="my-2"
        vs-type="flex"
        vs-align="center"
        vs-justify="center"
        v-for="(item, index) of reproduceVideo"
        :key="index"
      >
        <div class="border mx-1">
          <vx-card class="m-0 p-0" noShadow noRadius>
            <div class="my-1">
              <a style=" color:black" :href="item.sources[0].src" target="_blank">
                <span
                  class="font-semibold"
                >{{item.titulo ? item.titulo : "Tutorial " + (index + 1)}}</span>
              </a>
            </div>
          </vx-card>
          <vs-divider class="m-0 p-0"></vs-divider>
          <vx-card class="m-0 p-0" noShadow noRadius>
            <vs-row vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
              <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
                <video-player
                  class="vjs-custom-skin"
                  ref="videoPlayer"
                  :options="item"
                  :playsinline="true"
                ></video-player>
              </vs-col>
            </vs-row>
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reRender: 0,
      videos: [],
      reproduceVideo: [],
    };
  },
  methods: {
    async getVideosTreinamento() {
      await this.$vs.loading({
        container: "#loadingTreinamento",
        scale: 0.6,
      });
      try {
        this.videos = await this.$http.get("videos_treinamento");
        for (let item of this.videos) {
          let playerOptions = {
            titulo: item.titulo,
            height: "450",
            muted: true,
            autoplay: false,
            language: "pt-br",
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [
              {
                type: "video/mp4",
                src: item.url_video,
              },
            ],
          };
          this.reproduceVideo.push(playerOptions);
        }
        this.reRender += 1;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#loadingTreinamento > .con-vs-loading");
      }
    },
  },
  async mounted() {
    await this.getVideosTreinamento();
  },
};
</script>
<style scoped>
</style>
